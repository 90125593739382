import React from 'react'
import styles from './Address.module.css';
import AddressElement from './AddressElement';
const Address = ({address}) => {
  return (
    <div className={styles.address}>
<h1>Address</h1>
        {address ? 
        <div>
            {address.schoolName && <AddressElement label='School Name' data={address.schoolName}/>}
            {address.Line1 && <AddressElement  label='Line 1' data={address.Line1}/>}
            {address.Line2 && <AddressElement  label='Line 2' data={address.Line2}/>}
            {address.Line3 && <AddressElement  label='Line 3' data={address.Line3}/>}
            {address.city && <AddressElement  label='City' data={address.city}/>}
            {address.postcode && <AddressElement  label='Postcode' data={address.postcode}/>}
        </div>
        :
        <div>
            <h1>No Address Given</h1>
        </div>
        }

    </div>
  )
}

export default Address

