import React, {useState} from 'react';


import ImageUpload from '../../shared/components/FormElements/GeneralImageUpload';
import Button from '../../shared/components/UIElements/Buttons/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './ContentImageUpload.css';

const ContentImageUpload = ({ courseId, setImage }) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [img, setImg] = useState();

  const image = image => {
   setImg(image);
  }

  const onSubmit = async event => {
    event.preventDefault();
   
    try {
      //console.log(data.image[0]);
      const formData = new FormData();
      formData.append('featureImage', img);
      formData.append('courseId', courseId);

      const response = await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/course/add-featured-image`,
        'PATCH',
        formData
      );

      console.log(response.result);

      if (response.result.featureImage) {
        setImage(response.result.featureImage);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <section id="content-image-upload__wrapper">
      <ErrorModal
        error={error}
        className={'fm-modal-sm'}
        onClear={clearError}
      />
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <form onSubmit={onSubmit} className="content-image-upload-form">
          <label>
            <span className="inputError">
            
            </span>
          </label>
          <ImageUpload id="image" chosenImage={image}/>
          <Button
            type="submit"
            className="button-secondary upload-gpimg-btn"
            title="upload"
          />
          <span className="upload-rules">
            images must be either .png .jpeg & less than 10MB
          </span>
        </form>
      )}
    </section>
  );
};

export default ContentImageUpload;
