import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHttpClient } from '../../../shared/hooks/http-hook';
import Modal from '../../../shared/components/UIElements/Modal/Modal';
import VideoPlayer from '../../../shared/components/Video/VideoPlayer';
import Card from '../../../shared/components/UIElements/Card';
import { AuthContext } from '../../../shared/context/auth-context';
import Button from '../../../shared/components/UIElements/Buttons/Button';
import LessonVideoUpload from './LessonVideoUpload';
import ContentPDFUpload from './lessonPDFUpload';
import UpdateAssignmentDesc from '../assignments/UpdateAssignmentDesc';
import UpdateLessonDuration from './UpdateLessonDuration';
import Spinner from '../../../shared/components/UIElements/LoadingSpinner';

import styles from './Lesson.module.css';
import UpdateOrderNumber from './UpdateNumber';
import ErrorModal from '../../../shared/components/UIElements/Modal/ErrorModal';

const Lesson = ({ lessonData, update, sortList, deletedLessonId }) => {
  
  const { REACT_APP_HFM_API_URL } = process.env;
  const auth = useContext(AuthContext);

  const [showEditPdf, setShowEditPdf] = useState(false);
  const [showEditVideo, setShowEditVideo] = useState(false);
  const [showEditDuration, setShowEditDuration] = useState(false);
  const [showEditDescription, setShowEditDescription] = useState(false);
  const [showOrderNumber, setShowOrderNumber] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const [videoUrl, setVideoUrl] = useState();
  const [pdfUrl, setPdfUrl] = useState();
  const [text, setText] = useState();
  const [duration, setDuration] = useState();
  const [orderNumber, setOrderNumber] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    if (lessonData.lessonNumber) {
      setOrderNumber(lessonData.lessonNumber);
    }
    if (lessonData.text) {
      setText(lessonData.text);
    }
    if (lessonData.duration) {
      setDuration(lessonData.duration);
    }
    if (lessonData.videoUrl) {
      setVideoUrl(lessonData.videoUrl);
    }
    if (lessonData.downloadableUrl) {
      setPdfUrl(lessonData.downloadableUrl);
    }
  }, [lessonData]);

  const updatePdfStateHandler = data => {
    setShowEditPdf(false);
    setPdfUrl(data.downloadableUrl);
  };

  const updateVideoStateHandler = data => {
    setShowEditVideo(false);
    setVideoUrl(data);
  };

  const updateDescStateHandler = data => {
    setShowEditDescription(false);
    setText(data);
  };

  const updateOrderStateHandler = data => {
    setShowOrderNumber(false);
    setOrderNumber(data);
  };

  const updateDurationStateHandler = data => {
    setShowEditDuration(false);
    setDuration(data);
  };


  const deleteLesson = async () => {
    //console.log('deleting');
    try {
      const response = await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/lesson/${lessonData.id}`,
        'DELETE',
        {
          Authorization: 'Bearer ' + auth.token,
        }
      );
      setShowDeleteWarning(false);
      console.log(response);
      deletedLessonId(lessonData.id);
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <Card className={styles.lessonCard}>
      <section id={styles.lesson}>
        <div className={styles.lessonNumber}>{orderNumber}</div>
        <h1 className={styles.lessonTitle}>{lessonData.title}</h1>
        <div className={styles.lessonMediaWrapper}>
          <div className={styles.lessonVideoContainer}>
            {!videoUrl ? (
              <div className={styles.videoNone}>
                <div className={styles.videoNoneContent}>
                  <p className={styles.videoNoneContentText}>no video</p>
                </div>
              </div>
            ) : (
              <VideoPlayer url={videoUrl} />
            )}
          </div>
          <div className={styles.downloadableContainer}>
            <FontAwesomeIcon icon="file-pdf" size="3x" />
            {pdfUrl ? (
              <a
                className={styles.downloadableContentLink}
                href={`${REACT_APP_HFM_API_URL}/${pdfUrl}`}
                download={lessonData.title}
                target="blank"
              >
                Download file
              </a>
            ) : (
              <h1>no downloadable content</h1>
            )}
          </div>
        </div>
        <h2 className={styles.textTitle}>Instructions</h2>
        <div className={styles.text}>
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        </div>
      
        <div className={styles.duration}>
          <p> Duration: </p>
          <p>{duration}</p>
        </div>
        <div className={styles.actionsWrapper}>
          <Button
            className={`button-primary ${styles.actionBtn}`}
            title="edit description"
            onClick={() => setShowEditDescription(true)}
          />
           <Button
            className={`button-primary ${styles.actionBtn}`}
            title="edit lesson Number"
            onClick={() => setShowOrderNumber(true)}
          />

          <Button
            className={`button-primary ${styles.actionBtn}`}
            title="edit lesson duration"
            onClick={() => setShowEditDuration(true)}
          />
          <Button
            className={`button-primary ${styles.actionBtn}`}
            title="update video"
            onClick={() => setShowEditVideo(true)}
          />
          <Button
            className={`button-primary ${styles.actionBtn}`}
            title="update pdf"
            onClick={() => setShowEditPdf(true)}
          />
         
          <Button
            className={`button-danger ${styles.actionBtn}`}
            title="delete"
            onClick={() => setShowDeleteWarning(true)}
          />
        </div>

        <div className={styles.footer}>
          <p>
            last updated:{' '}
            <b>{moment(lessonData.updatedAt).format('MMM Do YYYY')}</b>
          </p>
          <p>
            created: <b>{moment(lessonData.createdAt).format('MMM Do YYYY')}</b>
          </p>
        </div>
      </section>

      <Modal show={showEditPdf} hideButton={true}>
        <div className="update-course-modal__wrapper">
          <h1>Update PDF</h1>
          <ContentPDFUpload
            lessonId={lessonData.id}
            setFile={updatePdfStateHandler}
            
          />
          <Button
            className="button-secondary"
            title="cancel"
            onClick={() => setShowEditPdf(false)}
          />
        </div>
      </Modal>

      <Modal show={showEditVideo} hideButton={true}>
        <div className="update-course-modal__wrapper">
          <h1>Update course Video</h1>
          <LessonVideoUpload
            lessonId={lessonData.id}
            setVideo={updateVideoStateHandler}
            
          />
          <Button
            className="button-secondary"
            title="cancel"
            onClick={() => setShowEditVideo(false)}
          />
        </div>
      </Modal>

      <Modal show={showEditDescription} hideButton={true} fullscreen>
        <div className="update-desc-course-modal__wrapper">
          <h1>Update course Description</h1>
          <UpdateAssignmentDesc
            lessonId={lessonData.id}
            text={text}
            close={updateDescStateHandler}
          />
          <Button
            className="button-secondary"
            title="cancel"
            onClick={() => setShowEditDescription(false)}
          />
        </div>
      </Modal>

      <Modal show={showEditDuration} hideButton={true}>
        <div className="update-price-course-modal__wrapper">
          <h1>Duration</h1>
          <UpdateLessonDuration
            lessonId={lessonData.id}
            value={lessonData.duration}
            updateLesson={update}
            close={updateDurationStateHandler}
          />
          <Button
            className="button-secondary price-edit-modal-btn"
            title="cancel"
            onClick={() => setShowEditDuration(false)}
          />
        </div>
      </Modal>

      <Modal show={showOrderNumber} hideButton={true}>
        <div className="update-price-course-modal__wrapper">
          <h1>Lesson Order Number</h1>
          <UpdateOrderNumber
            lessonId={lessonData.id}
            updateLesson={sortList}
            orderNumber={lessonData.lessonNumber}
            close={updateOrderStateHandler}
          />
          <Button
            className="button-secondary price-edit-modal-btn"
            title="cancel"
            onClick={() => setShowOrderNumber(false)}
          />
        </div>
      </Modal>

      <Modal show={showDeleteWarning} hideButton={true}>
        <div className="assignment-warning">
          <h1>Warning!</h1>
          <p>Are you sure you want to delete the lesson?</p>
          {isLoading ? (
            <Spinner />
          ) : (
            <div className={styles.warningActions}>
            <Button
                className="button-danger warning-button"
                title="delete"
                onClick={deleteLesson}
              />
              <Button
                className="button-secondary warning-button"
                title="cancel"
                onClick={() => setShowDeleteWarning(false)}
              />
              
            </div>
          )}
        </div>
      </Modal>
      <ErrorModal error={error} onClear={clearError} />
    </Card>
  );
};

export default Lesson;
