import React, { Fragment, useEffect, useState, useContext } from 'react';
import moment from 'moment';

import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Input from '../../shared/components/FormElements/Input';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';

import './UpdateTutorDetails.css';

const UpdateStaffDetails = ({ staff }) => {
  const { REACT_APP_HFM_API_URL } = process.env;
  const { token, userId } = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [firstName, setFirstName] = useState('first name');
  const [lastName, setLastName] = useState('last name');
  const [email, setEmail] = useState('email');
  const [lastupdated, setLastupdated] = useState('01 01 2000');

  useEffect(() => {
    if (staff) {
      setFirstName(staff.firstName);
      setLastName(staff.lastName);
      setEmail(staff.email);
      setLastupdated(staff.updatedAt);
    }
  }, [staff]);

  const updateFirstName = e => {
    setFirstName(e.target.value);
  };

  const updateLastName = e => {
    setLastName(e.target.value);
  };

  const updateEmail = e => {
    setEmail(e.target.value);
  };

  const onSubmit = async event => {
    event.preventDefault();

    try {
      await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/staff/update-staff/${userId}`,
        'PATCH',
        JSON.stringify({
          firstName: event.target.firstName.value,
          lastName: event.target.lastName.value,
          email: event.target.email.value,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }
      );
    } catch (err) {
      //console.log(err);
    }
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <Spinner
            className="spinner-primary"
            classNameText="spinner-text-primary"
            message="updating"
          />
        </div>
      )}
      {!isLoading && (
        <form onSubmit={onSubmit}>
          <label>
            <span className="inputError">
            
            </span>
          </label>
          <Input
            id="firstName"
            name="firstName"
            groupFront="First Name"
            value={firstName}
            onChange={updateFirstName}
            
          />

          <label>
            <span className="inputError">
             
            </span>
          </label>
          <Input
            id="lastName"
            name="lastName"
            groupFront="Last Name"
            value={lastName}
            onChange={updateLastName}
            
          />

          <label>
            <span className="inputError">
              
            </span>
          </label>
          <Input
            id="email"
            name="email"
            groupFront="email"
            value={email}
            onChange={updateEmail}
          />
          <p className="personal-settings-container-p">
            Last updated: {moment(lastupdated).format('MMM Do YYYY')}
          </p>
          <Button
            type="submit"
            className="button-primary personal-update-btn"
            title="update"
          />
        </form>
      )}
    </Fragment>
  );
};

export default UpdateStaffDetails;
