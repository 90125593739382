import React, {useContext} from 'react';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

const DeleteFeaturedVideo = ({ courseId }) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const deleteCourse = async () => {
    //console.log('deleting');
    try {
      const response = await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/course/video/${courseId}`,
        'DELETE',
        {
          Authorization: 'Bearer ' + auth.token,
        }
      );

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <LoadingSpinner />
        </div>
      )}
      <Button className="button-danger" title="Delete Video" type='button' onClick={deleteCourse}/>
    </>
  );
};

export default DeleteFeaturedVideo;
