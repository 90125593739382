import React, { useState, useEffect, Fragment, useContext } from 'react';
import moment from 'moment';

import { useHttpClient } from '../../shared/hooks/http-hook';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import VideoPlayer from '../../shared/components/Video/VideoPlayer';
import Button from '../../shared/components/UIElements/Buttons/Button';
import { AuthContext } from '../../shared/context/auth-context';

import ContentImageUpload from './ContentImageUpload';
import ContentVideoUpload from './ContentVideoUpload';
import Modal from '../../shared/components/UIElements/Modal/Modal';
import UpdateCourseDesc from './UpdateCourseDesc';
import UpdateCoursePrice from './UpdateCoursePrice';
import LessonList from './lessons/LessonList';

import './Course.css';
import ToggleCourse from './ToggleCourse';
import DeleteFeaturedVideo from './DeleteFeaturedVideo';
import DeleteFeaturedImage from './DeleteFeaturedImage';

const Course = ({ course, close, update }) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const auth = useContext(AuthContext);

  const [featuredImageUrl, setFeaturedImageUrl] = useState();
  const [courseVideoUrl, setCourseVideoUrl] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [showEditImage, setShowEditImage] = useState(false);
  const [showEditVideo, setShowEditVideo] = useState(false);
  const [showEditDesc, setShowEditDesc] = useState(false);
  const [showEditPrice, setShowEditPrice] = useState(false);
  const [descHtml, setDescHtml] = useState();
  const [price, setPrice] = useState();
  const [loadedCourse, setLoadedCourse] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    if(course) {
      setLoadedCourse(course);
    }
    if (course.featureImage) {
      setFeaturedImageUrl(course.featureImage);
    }
    if (course.videoUrl) {
      setCourseVideoUrl(course.videoUrl);
    }

    if (course.description) {
      setDescHtml(course.description)
    }

    if (course.price) {
      setPrice(course.price)
    }

   
  }, []);


  const updateImageStateHandler = imageUrl => {
    setFeaturedImageUrl(imageUrl);
    if (showEditImage) {
      setShowEditImage(false);
    }
  };

  const updateVideoStateHandler = videoUrl => {
    setCourseVideoUrl(videoUrl);
    if(showEditVideo) {
      setShowEditVideo(false);
    }
  };

  const updateDescStateHandler = textHtml => {
    setDescHtml(textHtml);
    const updatedCourse = loadedCourse;
    updatedCourse.description = textHtml;
    setLoadedCourse(updatedCourse); 
    
    if(showEditDesc) {
      setShowEditDesc(false);
    }
  };

  const updatePriceStateHandler = price => {
    setPrice(price);
    const updatedCourse = loadedCourse;
    updatedCourse.price = price;
    setLoadedCourse(updatedCourse); 
    
    if(showEditPrice) {
      setShowEditPrice(false);
    }
  
  };

  const deleteCourse = async () => {
    //console.log('deleting');
    try {
      const response = await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/course/${course.id}`,
        'DELETE',
        {
          Authorization: 'Bearer ' + auth.token,
        }
      );

      console.log(response);
      
      close(0);
    } catch (error) {
      console.log(error);
    }
  };

  const updateLessonHandler = lesson => {
    console.log('course update req');
    update()
    // let lessonListToUpdate = lessons
    // const idx = lessonListToUpdate.findIndex(item => item.id === lesson.id);
    // console.log(idx);
    // lessonListToUpdate[idx] = lesson;
    // lessonListToUpdate.sort(compare);
    // setLessons(lessonListToUpdate);
    // console.log(lessonListToUpdate);
  }

  const compare = ( a, b ) => {
    console.log('compareing');
    if ( a.lessonNumber < b.lessonNumber){
      return -1;
    }
    if ( a.lessonNumber > b.lessonNumber ){
      return 1;
    }
    return 0;
  }

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <LoadingSpinner />
        </div>
      )}
      <section id="course">
        <h1 className="course-details-title">{course.title}</h1>
         <div className="course-details-media__wrapper">

          <Modal show={showEditImage} hideButton={true}>
            <div className='update-course-modal__wrapper'>
                <h1>Update featured Image</h1>
                <ContentImageUpload courseId={course.id} setImage={updateImageStateHandler}/>
                <Button className='button-secondary' title='cancel' onClick={()=> setShowEditImage(false)}/>
            </div>
          </Modal>

          <Modal show={showEditVideo} hideButton={true}>
            <div className='update-course-modal__wrapper'>
                <h1>Update course Video</h1>
                <ContentVideoUpload courseId={course.id} setVideo={updateVideoStateHandler}/>
                <Button className='button-secondary' title='cancel' onClick={()=> setShowEditVideo(false)}/>
            </div>
          </Modal>

          <Modal show={showEditDesc} hideButton={true} fullscreen >
            <div className='update-desc-course-modal__wrapper'>
                <h1>Update course Description</h1>
               <UpdateCourseDesc course={loadedCourse} close={updateDescStateHandler} />
                <Button className='button-secondary' title='cancel' onClick={()=> setShowEditDesc(false)} />
            </div>
          </Modal>
        
          <Modal show={showEditPrice} hideButton={true}>
            <div className='update-price-course-modal__wrapper'>
                <h1>Update price</h1>
                <UpdateCoursePrice course={loadedCourse} close={updatePriceStateHandler} />
                <Button className='button-secondary price-edit-modal-btn' title='cancel' onClick={()=> setShowEditPrice(false)} />
            </div>
          </Modal>
          
          {!featuredImageUrl && (
            <div className="course-featured-image-none">
              <ContentImageUpload
                courseId={course.id}
                setVideo={updateImageStateHandler}
              />
            </div>
          )}

          {featuredImageUrl && (
            <div className="course-featured-image-none">
            <div className="course-feature-image-container">
            <img className="course-feature-image" src={`${REACT_APP_HFM_API_URL}/${featuredImageUrl}`} alt='feature'/>
            <div className='featureActions'>
            <Button className="button-secondary" title="update image" onClick={()=> {setShowEditImage(true)}}/>
            <DeleteFeaturedImage courseId={course.id}/>
            </div>
          </div>
            </div>
          )}

          <div className="course-video__wrapper">
            {!courseVideoUrl ? (
              <div className="course-featured-video-none">
                <ContentVideoUpload
                  courseId={course.id}
                  setVideo={updateVideoStateHandler}
                />
              </div>
            ) : (
              <VideoPlayer url={courseVideoUrl} />
            )}
          </div>
          <div className="course-edit-media-actions">
           
            {courseVideoUrl && (
              <Button className="button-secondary" title="update video" onClick={()=> {setShowEditVideo(true)}}/>
            )}
            {courseVideoUrl && (
              <DeleteFeaturedVideo courseId={course.id}/>
            )}
          </div>
        </div>
        <div className="course-description__wrapper wrapper-bottom">
          <h2>Description</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: descHtml,
            }}
          ></div>
          <div className='course-edit-desc-action__wrapper'>
            <Button className='course-description-edit-btn' title='edit description' onClick={()=> {setShowEditDesc(true)}}/>
          </div>
         
        </div>
        <div className='course-right-col'>
        {loadedCourse && 
          <div className="course-price__wrapper">
          <h2 className='course-price-title'>Price</h2>
          <div className="course-price__content">
            <h2 className='course-price-price'>{`£${(price / 100).toFixed(2)}`}</h2>
            <div className='course-edit-price-action__wrapper'>
              <Button className='course-price-edit-btn' title='edit price' onClick={()=> {setShowEditPrice(true)}}/>
            </div>
          </div>
         
        </div>
        }
        {loadedCourse && 
          <div className="course-price__wrapper">
          <h2 className='course-price-title'>Course Active</h2>
          <div className="course-price__content">
            <div className='course-edit-price-action__wrapper'>
              <ToggleCourse data={loadedCourse} />
            </div>
          </div>
         
        </div>
        }
        </div>
       
        {course.id && <LessonList lessons={course.lessons} courseId={course.id} update={updateLessonHandler} />}
        <div className="course-stats__wrapper wrapper-bottom"></div>
        <div className="course-dates__wrapper">
          <p>
            <span>Created On: </span>{' '}
            {moment(course.createdAt).format('MMM Do YYYY')}
          </p>
          <p>
            <span>Updated On: </span>{' '}
            {moment(course.updatedAt).format('MMM Do YYYY')}
          </p>
        </div>
        <div className="course-actions__wrapper wrapper-bottom">
         
          <Button
            className="button-danger-outline"
            title="Delete Course"
            onClick={() => setShowWarning(true)}
          />
        </div>
      </section>
      <Modal
        className="delete-warning-wrapper"
        show={showWarning}
        hideButton={true}
      >
        <h1>Are you sure?</h1>
        <p className="delete-warning-message">
          <b>This cannot be undone.</b> <br />
          This will completely remove this course from the system.
        </p>
        <div className="warning-action__wrapper">
          <Button
            className="button-secondary warning-button"
            title="Cancel"
            onClick={() => setShowWarning(false)}
          />
          <Button
            className="button-danger warning-button"
            title="Delete"
            onClick={() => deleteCourse()}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default Course;
