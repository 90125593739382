import React, { Fragment, useContext, useState } from 'react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import Input from '../FormElements/Input';
import Checkbox from '../FormElements/Checkbox';
import ErrorModal from '../UIElements/Modal/ErrorModal';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinner from '../UIElements/LoadingSpinner';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import './AddCourseForm.css';

const AddCourseForm = ({selected}) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [textData, setTextData] = useState();
  const [errors, setErrors] = useState();
  const auth = useContext(AuthContext);

  const onSubmit = async event => {
    event.preventDefault();
    //console.log(textData);
    //console.log(data);
    try {
      await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/course/create-course`,
        'POST',
        JSON.stringify({
          title: event.target.title.value,
          description: textData,
          price: event.target.price.value,
          active: event.target.active.value,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      selected(0);
    } catch (err) {
      //console.log(err);
    }
  };

  const onEditorStateChange = editorState => {
    setTextData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner />}
      <div className="add-course-form__wrapper">
        <form onSubmit={onSubmit}>
          <div className="add-course-form-action">
            <Button className='add-course-form-btn' title="Save Course" type="sumbit" />
          </div>
          <div className="add-course-left_col">
            <label>
              Course Title &nbsp;&nbsp;
              {errors && <span className="inputError">
                {errors.title &&
                  errors.title.type === 'required' &&
                  'The course title is required!'}
                {errors.title &&
                  errors.title.type === 'minLength' &&
                  'At least 5 characters are required!'}
              </span>}
            </label>
            <Input
              className={` no-group`}
              id="title"
              name="title"
            />

            <label>
              Price &nbsp;&nbsp;
              {errors && <span className="inputError">
                {errors.price &&
                  errors.price.type === 'required' &&
                  'The price is required!'}
              </span>}
            </label>
            <Input
              className={``}
              id="price"
              name="price"
              groupFront="£"
            
            />

            <div className="show-form-checkbox-container">
              <h3 className="show-form-checkbox-title">Active: </h3>
              <Checkbox id="active" name="active" />
            </div>
          </div>
        </form>
        <div className='add-course-main-description' >
        <h2>Description</h2>
        <Editor
          wrapperClassName="course-form-editor-wrapper"
          editorClassName="editor"
          toolbarClassName="toolbar"
          onEditorStateChange={onEditorStateChange}
        />
        </div>
      </div>
    </Fragment>
  );
};

export default AddCourseForm;
