import React, { useState, useEffect } from 'react';
import { motion} from 'framer-motion';



import './CourseList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CourseList = ({selected, courses}) => {
  
 


  return (
    <motion.section 
      id="course-list"
      layout
      initial={{ opacity: 0, scale: 1}}
      animate={{
        opacity: 1,
        scale: 1,
        duration: 3
      }}
      exit={{ opacity: 0, scale: 1}}
    >
      

      {courses && courses.map(course => (
          <div key={course.id} className='course-list-item__wrapper' onClick={() => {selected(course)}}>
            <div className='course-list-item__header'>
                <h2 className='course-list-item__title'>{course.title}</h2>
            </div>
            <div className='course-list-item__body'>
            <div className='course-list-item__item'>
                <h4 className='course-list-item__item-label'>Lessons: </h4>
                <h4 className='course-list-item__item-data'>{course.lessons.length}</h4>
            </div>
            <div className='course-list-item__item'>
                <h4 className='course-list-item__item-label'>Video: </h4>
                <h4 className='course-list-item__item-data'>{course.videoUrl ? <FontAwesomeIcon icon="check-circle" size="1x" /> :  <FontAwesomeIcon icon="times-circle" size="1x" />}</h4>
            </div>
            <div className='course-list-item__item'>
                <h4 className='course-list-item__item-label'>Active: </h4>
                <h4 className='course-list-item__item-data'>{course.active ? <FontAwesomeIcon icon="check-circle" size="1x" /> :  <FontAwesomeIcon icon="times-circle" size="1x" />}</h4>
            </div>
            <div className='course-list-item__item'>
                <h4 className='course-list-item__item-label'>Price: </h4>
                <h4 className='course-list-item__item-data'>£{(course.price / 100).toFixed(2)}</h4>
            </div>

            </div>


          </div>
        ))}
    </motion.section>
  );
};

export default CourseList;
