import React, { Fragment, useContext, useState } from 'react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import Input from '../FormElements/Input';
import ErrorModal from '../UIElements/Modal/ErrorModal';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinner from '../UIElements/LoadingSpinner';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import styles from './AddLessonForm.module.css';

const AddLessonForm = ({courseId, selected, newLesson}) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [errors, setErrors] = useState();
  const [textData, setTextData] = useState();
  const auth = useContext(AuthContext);

  const onSubmit = async event => {
   
   event.preventDefault();
    // console.log(courseId);
    // console.log(textData);
    // console.log(event.target.number.value);
    // console.log(event.target.duration.value);
    // console.log(`${REACT_APP_HFM_API_URL}/api/course/create`,);
    try {
      const response = await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/lesson/create`,
        'POST',
        JSON.stringify({
          title: event.target.title.value,
          text: textData,
          courseId: courseId,
          lessonNumber: event.target.number.value,
          duration: event.target.duration.value
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      console.log(response);
      if (response){
        if(response.lesson) {
          newLesson(response.lesson);
        }
      }

      selected(0);
    } catch (err) {
      //console.log(err);
    }
  };


  const onEditorStateChange = editorState => {
    setTextData(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

 
  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
     

      {isLoading && <LoadingSpinner />}
      <div className={styles.wrapper}>
        <form onSubmit={onSubmit}>
          <div className={styles.formAction}>
            <Button className={styles.btn} title="Save Lesson" type="sumbit" />
          </div>
          <div className={styles.left}>
            
            <Input
              className={``}
              id="title"
              name="title"
              groupFront="Lesson Title: "
            />

            <Input
                wrapperClassName={styles.numberInput}
              className={``}
              id="number"
              name="number"
              type="number"
              groupFront="Lesson No: "
             
            />

            <label>
              
            </label>
            <Input
              wrapperClassName={`${styles.numberInput} ${styles.durationInput} ${styles.inputLeftMargin}`}
              className={` `}
              id="duration"
              name="duration"
              type="number"
              groupFront="Lesson Duration:"
              groupBack="Mins"
            />

          </div>
        </form>
        <div className={styles.description} >
        <h2>Description</h2>
        <Editor
          wrapperClassName={styles.editorWrapper}
          editorClassName="editor"
          toolbarClassName="toolbar"
          onEditorStateChange={onEditorStateChange}
        />
        </div>

      </div>
    </Fragment>
  );
};

export default AddLessonForm;
