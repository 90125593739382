import React, {useState, useEffect} from 'react';

import SubMenu from '../../shared/components/nav/subMenu';
import CourseList from '../components/CourseList';
import AddCourse from '../components/AddCourse';

import { useHttpClient } from '../../shared/hooks/http-hook';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import Course from '../components/Course'
import './Courses.css';


const courseMenu = [
  {
    id: 0,
    title: 'list'
  },
  {
    id: 1,
    title: 'add course'
  }
]


const Courses = ({setTitle}) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [courses, setCourses] = useState([]);
  const [showList, setShowList] = useState(true);
  const [showCourse, setShowCourse] = useState(false);
  const [showAddCourse, setShowAddCourse] = useState(false);
  const [course, setCourse] = useState({});
  const [update, setUpdate] = useState(false);
  
  useEffect(()=> {
    setTitle('Courses');
  }, []);

  const subMenuStateHandler = (id) => {
    //console.log(id);
    
    switch (id) {
      case 0:
        setShowCourse(false);
        setShowAddCourse(false);
        setShowList(true); 
        setUpdate(state => !state);      
        break;
      case 1:
          setShowCourse(false);
          setShowAddCourse(true);
          setShowList(false);        
        break;
    
      default:
        break;
    }
  }

  useEffect(() => {
    console.log('fetching');
    const fetchCourses = async () => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_HFM_API_URL}/api/course/`,
          'POST',
        );
        setCourses(responseData.courses);
        console.log(responseData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCourses();
  }, [update]);



  const stateHandler = (data) => {

    if(data) {
      setCourse(data)
      setShowCourse(true);
      setShowAddCourse(false);
      setShowList(false);       
    } else {
      setShowCourse(false);
      setShowAddCourse(false);
      setShowList(true);  
    }

  }

  return (
    <div className="page__wrapper">
  
      <SubMenu items={courseMenu} click={subMenuStateHandler} />
      <div className="page-content">
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <LoadingSpinner />
        </div>
      )}
      {showList && <CourseList selected={stateHandler} courses={courses}/>}
      {showAddCourse && <AddCourse close={subMenuStateHandler}/>}
      {showCourse && <Course course={course} close={subMenuStateHandler} update={()=>setUpdate(state => !state)}/>}

        
      </div>
    </div>
  );
};

export default Courses;
