import React, { useEffect, useState } from 'react';

import VideoPlayer from '../Video/VideoPlayer';
import './GeneralVideoUpload.css';

const GeneralVideoUpload = props => {

    const [file, setFile] = useState();
    const [previewUrl, setPreviewUrl] = useState();

    useEffect (() => {
        if (!file) {
            setPreviewUrl(null)
            return; 
            
        } else {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setPreviewUrl(fileReader.result);
                console.log(fileReader.result);
                props.chosenVideo(file)
            };
            fileReader.readAsDataURL(file);
           
            
        }
    }, [file])

    const pickHandler = data => {
        if(data.target.files) {
           const pickedFile = data.target.files[0];
           setFile(pickedFile);
        }    
    };

    return (
        <div className='file-selection__wrapper'>
        <label htmlFor={props.id}>
            <div  className="button button-primary upload-gpvideo-btn"> 
                <span>choose a Video file</span>
            </div>
            {previewUrl && <VideoPlayer local={true} url={previewUrl} />}
            
        </label>
            <input 
                id={props.id} 
                name={props.id}
                type="file" 
                accept=".mp4,.webm, .mov" 
                onChange={pickHandler} 
            />
        </div>
    );
};


export default GeneralVideoUpload;