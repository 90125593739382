import React, {useState} from 'react';

import VideoUpload from '../../shared/components/FormElements/GeneralVideoUpload';
import Button from '../../shared/components/UIElements/Buttons/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './ContentVideoUpload.css';

const ContentVideoUpload = ({ courseId, setVideo }) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [videoFile, setVideoFile] = useState();

  const onSubmit = async event => {
    event.preventDefault();

    console.log(videoFile);

    try {
      //console.log(data.video[0]);
      const formData = new FormData();
      formData.append('featureVideo',videoFile);
      formData.append('courseId', courseId);

      const response = await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/course/add-featured-video`,
        'PATCH',
        formData
      );

      //console.log(response.result);

      if (response.result.videoUrl) {
        setVideo(response.result.videoUrl);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <section id="content-video-upload__wrapper">
      <ErrorModal
        error={error}
        className={'fm-modal-sm'}
        onClear={clearError}
      />
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <form
          onSubmit={onSubmit}
          className="content-video-upload-form"
        >
          <label>
            <span className="inputError">
             
            </span>
          </label>
          <VideoUpload id="video" chosenVideo={setVideoFile} />
          <Button
            type="submit"
            className="button-secondary upload-gpvideo-btn"
            title="upload"
          />
          <span className="upload-rules">
            Videos must be either .mp4 .mov or .webm <br/> and must be less than 100MB
          </span>
        </form>
      )}
    </section>
  );
};

export default ContentVideoUpload;
