import { faBullhorn, faColumns, faComments, faUsers, faTachometerAlt, faCog, faGraduationCap} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { NavLink } from 'react-router-dom'; 
import TooltipBasic from '../UIElements/tooltip/TooltipBasic';


import './mainNav.css';

 const MainNav = () => {


    return (
        <div className="main-nav__wrapper">
            <ul className="nav-links">
            <li>
                <NavLink to={`/dashboard`}>
                    <TooltipBasic label='Dashboard'>
                        <FontAwesomeIcon icon={faColumns}/>
                    </TooltipBasic>
                </NavLink>
                </li>
        
                <li>
                    <NavLink to={`/users`}>
                        <TooltipBasic label='Users'>
                            <FontAwesomeIcon icon={faUsers}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
              
                <li>
                    <NavLink to={`/courses`}>
                        <TooltipBasic label='Courses'>
                            <FontAwesomeIcon icon={faGraduationCap}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
              
                <li>
                    <NavLink to={`/settings`}>
                        <TooltipBasic label='Settings'>
                            <FontAwesomeIcon icon={faCog}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
            </ul>
 
        </div>
    )
}


export default MainNav;