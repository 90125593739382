import React, { Fragment, useState, useEffect, useContext } from 'react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';
import { AuthContext } from '../../shared/context/auth-context';

const UpdateCourseDesc = ({ course, close }) => {
  
  const { REACT_APP_HFM_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (course) {
      let contentState = stateFromHTML(course.description);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [course, course.description]);

  const save = async () => {
    const desc = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    //console.log(desc);
    try {
      await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/course/update-course/${course.id}`,
        'PATCH',
        JSON.stringify({ description: desc }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      close(desc);
    } catch (err) {
      //console.log(err);
    }
  };

  const onEditorStateChange = state => {
    setEditorState(state);
  };

  return (
    <Fragment>
      {isLoading && <Spinner />}
      {error && <p className="course-form-editor-error">{error}</p>}
      {course && (
        <Editor
          wrapperClassName="course-form-editor-wrapper"
          editorClassName="editor"
          toolbarClassName="toolbar"
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      )}
      {!isLoading && <Button title="save" onClick={save} />}
    </Fragment>
  );
};

export default UpdateCourseDesc;
