import React from 'react'
import styles from './AddressElement.module.css';

const AddressElement = ({label, data}) => {
  return (
    <div className={styles.wrapper}>
        <p className={styles.content}><span>{label}:</span>{data}</p>
    </div>
  )
}

export default AddressElement