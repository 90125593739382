import React from 'react';
import Button from '../Buttons/Button';

import Modal from './Modal';



const ErrorModal = ({error, onClear}) => {
  return (
    <Modal
      onCancel={onClear}
      show={!!error}
      footer={<Button onClick={onClear} title="Ok"/>}
    >
    <h1>Error!</h1>
      <p>{error}</p>
    </Modal>
  );
};

export default ErrorModal;
