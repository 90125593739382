import React from 'react';

import './LoadingSpinner.css';

const Spinner = (props) => {

  return (
    <div className={`lds-roller ${props.className}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <p id="lds-roller-text"  className={`${props.classNameText}`}>{props.message ? props.message : 'loading'}</p>
    </div>
  );
};

export default Spinner;
