import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Address from './Address';

import React from 'react'

import styles from './User.module.css';
import UserPosts from './UserPosts';

const User = ({user, back}) => {
  return (
    <div className={styles.user}>
        <p className={styles.backLink} onClick={back}><span><FontAwesomeIcon icon={ faArrowLeft}/></span> back to users</p>
        <div className={styles.userHeader}>
            <h1> {user.firstName} {user.lastName}</h1>
            <h2> {user.school}</h2>
        </div>
        <div className={styles.userDetails}>
           
            <Address address={user.address} />
            <div className={styles.postsContainer}>

            </div>  
            <UserPosts posts={user.discussionPosts}/>
        </div>
        

    </div>
  )
}

export default User