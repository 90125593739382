import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import './GeneralImageUpload.css';

const PdfUpload = props => {

    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();

    useEffect (() => {
        if (!file) {
            setFileName(null)
            return; 
            
        } else {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setFileName(fileReader.result);
            };
            fileReader.readAsDataURL(file);
            props.chosenFile(file)
        }
    }, [file])

    const pickHandler = data => {
        console.log(data.target.files[0]);
        if(data.target.files) {
           const pickedFile = data.target.files[0];
           setFile(pickedFile);
           
        }    
    };

    return (
        <div className='file-selection__wrapper'>
        <label htmlFor={props.id}>
            <div  className="button button-primary upload-gpimg-btn"> 
                <span>choose PDF file</span>
            </div>
            <FontAwesomeIcon icon="file-pdf" size="6x" />
           {file && <p>{file.name}</p>}
        </label>
            <input 
                id={props.id} 
                name={props.id}
                ref={props.refs}  
                type="file" 
                accept=".pdf" 
                onChange={pickHandler} 
            />
        </div>
    );
};


export default PdfUpload;