import React from 'react';

import './subMenu.css';

const SubMenu = props => {

    return (
        <div className='sub-menu__wrapper'>
            <ul className='sub-menu-list'>
                {props.items.map(i => (
                    <li key={i.id} onClick={() => {props.click(i.id)}} className='sub-menu-item'><p className='sub-menu-item-btn'>{i.title}</p></li>
                ))}
            </ul>
        </div>
    )
}

export default SubMenu;