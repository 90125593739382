import React from 'react';

import './Input.css';

const Input = props => {

    return (
        <div className={`input-wrapper ${props.wrapperClassName}`}>
            <div className={`form-group ${props.className}`}>
                {props.groupFront
                    ? <span>{props.groupFront}</span> 
                    : null
                }
                <input 
                    id={props.id} 
                    name={props.name}
                    type={props.type}
                    placeholder={props.placeholder}
                    className={`form-field ${props.inputClassName}` }
                    ref={props.refs} 
                    value={props.value}
                    onChange={props.onChange}
                />
                {props.groupBack
                    ? <span>{props.groupBack}</span>
                    : null
                }
                
            </div>
        </div>
    );
       
};        


export default Input;