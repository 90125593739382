import React from 'react'

import styles from './UserList.module.css';
import UserListItem from './UserListItem';

const UserList = ({users, selectedUser}) => {

  return (
    <div className={styles.userList}>
       {users && users.map(user => (
           <UserListItem key={user.id} user={user} selectedUser={selectedUser}/>
       ))}
    </div>
  )
}

export default UserList