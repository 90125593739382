import React from 'react'

import styles from './UserListItem.module.css';

const UserListItem = ({user, selectedUser}) => {
  return (
    <div className={styles.userListItem} onClick={()=>selectedUser(user)}>
        <div className={styles.name}> {user.firstName} {user.lastName} </div>
        <div className={styles.email}>{user.email}</div>
        <div className={styles.courses}>Paid Courses: {user.myCourses.length}</div>
        
    </div>
  )
}

export default UserListItem