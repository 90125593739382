import React, { useEffect, useState, useContext } from 'react';

import UserList from '../components/UserList';
import User from '../components/User';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import LoadingSpinner from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';

const Users = ({setTitle}) => {

    const { REACT_APP_HFM_API_URL } = process.env;
    const { token, isLoggedIn } = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [users, setUsers] = useState([]);
    const [update, setUpdate] = useState(false);
    const [showList, setShowList] = useState(true);
    const [selectedUser, setSelectedUser] = useState();

    useEffect(() => {
        setTitle('Users');
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {

            try {
               const response =  await sendRequest(
                    `${REACT_APP_HFM_API_URL}/api/teacher/list`,
                    'POST',
                    {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                    }
               )

               console.log(response);
               if (response) {
                   setUsers(response)
               }
            } catch (error) {
                console.log(error);
            }

        }
        isLoggedIn && fetchUsers()

    },[update])

    const selectUserHandler = user => {
        if (user) {
            console.log(user);
            setSelectedUser(user);
            setShowList(false);
        }
    }

    const backHandler = () => {
        console.log('click ');
        setShowList(true)
    }
    
      return (
        <div className="page__wrapper">
          <div className="page-content">
            {isLoading && <LoadingSpinner />}
            {!isLoading && showList && <UserList users={users} selectedUser={selectUserHandler}/>}
            {!isLoading && !showList && selectedUser && <User user={selectedUser} back={backHandler}/>}
          </div>
        </div>
      );
}

export default Users