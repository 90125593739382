import React, { useState, useEffect } from 'react';

import Lesson from './Lesson';
import Button from '../../../shared/components/UIElements/Buttons/Button';
import AddLessonForm from '../../../shared/components/Forms/AddLessonForm';

import styles from './LessonList.module.css';


const LessonList = ({ courseId, lessons }) => {

  const [showList, setShowList] = useState(true);
  const [showAddLesson, setShowAddLesson] = useState(false);
  const [lessonList, setLessonList] = useState([]);

  const addNewLesson = () => {
    setShowList(false);
    setShowAddLesson(true);
  }

  const cancelNewLesson = () => {
    setShowList(true);
    setShowAddLesson(false);
  }
  
  useEffect(() => {
    lessons && setLessonList(lessons);
  }, [lessons])

  const deleteLessonHandler = lessonId => {
    const idx = lessonList.findIndex(lesson => lesson.id === lessonId);
    const newList = lessonList;
    newList.splice(idx, 1);
    setLessonList([...newList])
  }
  
  const newLessonHandler = lesson => {
    setLessonList([...lessonList, lesson])
  }

  const sortList = lessonData => {
    const idx = lessonList.findIndex(lesson => lesson.id === lessonData.id);
    const newList = lessonList;
    newList[idx].lessonNumber = lessonData.lessonNumber;
    newList.sort(compare);
    setLessonList([...newList])
  }

 const compare = ( a, b ) => {
    if ( a.lessonNumber < b.lessonNumber){
      return -1;
    }
    if ( a.lessonNumber > b.lessonNumber ){
      return 1;
    }
    return 0;
  }

  return (
    <section  id={styles.lessonList}>

      <div className={styles.lessonListToolbar}>
          <h1>Lessons</h1>
          {showList && <Button title='create new' onClick={addNewLesson} />}
          {!showList && <Button className='button-secondary' title='cancel' onClick={cancelNewLesson} />}
      </div>
      <div className={styles.lessonListWrapper}>
     
      {showList && lessonList.length > 0 && lessonList.map(lesson => (
          <Lesson key={lesson.id} lessonData={lesson} sortList={sortList} deletedLessonId={deleteLessonHandler}  />
        ))}
        </div>
        {showAddLesson && 
          <div className={styles.addLessonWrapper}>
            <AddLessonForm courseId={courseId} selected={cancelNewLesson} newLesson={newLessonHandler}/>
          </div>
        }
    </section>
  );
};

export default LessonList;
