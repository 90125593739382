import React, { useContext, useState } from 'react';


import VideoUpload from '../../../shared/components/FormElements/GeneralVideoUpload';
import Button from '../../../shared/components/UIElements/Buttons/Button';
import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../../shared/components/UIElements/Modal/ErrorModal';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';
import styles from './LessonVideoUpload.module.css';

const LessonContentVideoUpload = ({ lessonId, setVideo }) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [videoFile, setVideoFile] = useState();

  const onSubmit = async event => {
    event.preventDefault();

    try {
     
      const formData = new FormData();
      formData.append('lessonVideo', videoFile);

      const response = await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/lesson/upload-video/${lessonId}`,
        'PATCH',
        formData,
        {
          Authorization: 'Bearer ' + auth.token,
        }
      );

      console.log(response.result);

      if (response.result.videoUrl) {
        setVideo(response.result.videoUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section id={styles.wrapper}>
     <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <form
          onSubmit={onSubmit}
          className={styles.uploadForm}
        >
          <label>
            <span className="inputError">
            
            </span>
          </label>
          <VideoUpload id="lessonVideo" chosenVideo={setVideoFile} />
          <Button
            type="submit"
            className="button-secondary upload-gpvideo-btn"
            title="upload"
          />
          <span className="upload-rules">
            Videos must be either .mp4 or .webm & less than 100MB
          </span>
        </form>
      )}
    </section>
  );
};

export default LessonContentVideoUpload;
