import React, { Fragment, useState, useEffect, useContext } from 'react';

import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/UIElements/Buttons/Button';
import Spinner from '../../shared/components/UIElements/LoadingSpinner';
import Input from '../../shared/components/FormElements/Input';
import { AuthContext } from '../../shared/context/auth-context';
import { useForm } from 'react-hook-form';

const UpdateCoursePrice = ({ course, close }) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest } = useHttpClient();
  const [price, setPrice] = useState(0);
  const { handleSubmit } = useForm();

  useEffect(() => {
    if (course) {
      setPrice(course.price / 100);
    }
  }, [course]);

  

  const onSubmit = data => {

    const save = async () => {
        try {
          await sendRequest(
            `${REACT_APP_HFM_API_URL}/api/course/update-course/${course.id}`,
            'PATCH',
            JSON.stringify({ price: price * 100 }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );
    
          close(price * 100);
        } catch (err) {
          //console.log(err);
        }
      };
      save();
      close(price);
  }

  const updatePriceHandler = e => {
      
    if (e.target.value !== '') {
        setPrice(e.target.value);
    } else {
        setPrice(0);
    } 
  }

  return (
    <Fragment>
      {isLoading && <Spinner />}
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input 
            id='price'
            name='price'
            groupFront="£"
            value={price} 
            onChange={updatePriceHandler}
            />
          <Button className='price-save-modal-btn' title="save" type='submit' />
        </form>
      )}

      {error && <p>{error}</p>}
    </Fragment>
  );
};

export default UpdateCoursePrice;
