import React, { useEffect, useState } from 'react';


import './GeneralImageUpload.css';

const GeneralImageUpload = ({id, chosenImage}) => {

    const [file, setFile] = useState();
    const [previewUrl, setPreviewUrl] = useState();

    useEffect (() => {
        if (!file) {
            setPreviewUrl(null)
            return; 
            
        } else {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setPreviewUrl(fileReader.result);
                chosenImage(file);
            };
            fileReader.readAsDataURL(file);
            
        }
    }, [file])

    const pickHandler = data => {
        if(data.target.files) {
           const pickedFile = data.target.files[0];
           setFile(pickedFile);
        }    
    };

    return (
        <div className='file-selection__wrapper'>
        <label htmlFor={id}>
            <div  className="button button-primary upload-gpimg-btn"> 
                <span>choose image</span>
            </div>
            {previewUrl && <img 
                className="gen-image-preview" 
                src={previewUrl} 
                alt={'uploaded'} 
            />}
        </label>
            <input 
                id={id} 
                name={id}
                type="file" 
                accept=".jpg,.png,.jpeg" 
                onChange={pickHandler} 
            />
        </div>
    );
};


export default GeneralImageUpload;