import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../context/auth-context';
import AvatarIcon from '../UIElements/AvatarIcon';
import './Header.css';

const Header = ({title}) => {
  const { firstName, userProfileImageUrl, logout } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [profileImage, setProfileImage] = useState('');

  useEffect(() => {
    setName(firstName);
    setPageTitle(title);
    setProfileImage(userProfileImageUrl);
  }, [firstName, userProfileImageUrl, title]);

  return (
    <div className="page-header__wrapper">
    <h1 className="page-title">{pageTitle}</h1>
      <div className="tutor-welcome__wrapper">
      <p className="welcome-text">Hello {name}</p>
       {profileImage && <AvatarIcon src={profileImage} alt={name} />}
        <button onClick={logout} className="logout">
          <FontAwesomeIcon icon="sign-out-alt" />
        </button>
      </div>
    </div>
  );
};

export default Header;
