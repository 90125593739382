import React, {useEffect} from 'react';

const Dashboard = ({setTitle}) => {


  useEffect(() => {
    setTitle('Dashboard');
  }, []);
  

  return (
    <div className="page__wrapper">
      
      <div className="page-content"></div>
    </div>
  );
};

export default Dashboard;
