import React from 'react';

import './Checkbox.css';

const Checkbox = props => {
  return (
    <div className={`${props.wrapperClassName}`}>
      <div className={`${props.className}`}>
        <label className="switch">
          <input
            type="checkbox"
            id={props.id}
            name={props.name}
            ref={props.refs}
            checked={props.isChecked}
            onChange={props.onChange}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
