import React from 'react';
import AddCourseForm from '../../shared/components/Forms/AddCourseForm';

import './AddCourse.css';

const AddCourse = ({close}) => {

    return (
        <section id='add-course'>
            <h1 className='add-course-title'>Add Course</h1>
            <AddCourseForm selected={close}/>
        </section>
       
    )
}

export default AddCourse;