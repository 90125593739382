import React, {useEffect, useState} from 'react';


import './AvatarIcon.css';


const AvatarIcon = props => {

    const { REACT_APP_HFM_API_URL, REACT_APP_HFM_PLACEHOLDER_URL } = process.env;
   
    const [src, setSrc] = useState()

    useEffect(() => {
        setSrc(props.src);
    }, [ props.src]);

    return (
        <div className={`avatar-icon-wrapper ${props.className}`}>
           <img 
            src={src ? `${REACT_APP_HFM_API_URL}/${src}` :  REACT_APP_HFM_PLACEHOLDER_URL} 
            alt={props.alt ? props.alt : 'avatar'} 
            />
        </div>
    )
}

export default AvatarIcon;