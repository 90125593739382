import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';
import Button from '../../../shared/components/UIElements/Buttons/Button';
import Spinner from '../../../shared/components/UIElements/LoadingSpinner';
import Input from '../../../shared/components/FormElements/Input';

const UpdateOrderNumber = ({ lessonId, orderNumber, close, updateLesson }) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [orderNum, setOrderNum] = useState(0);
  const { handleSubmit } = useForm();

  useEffect(()=>{
    orderNumber && setOrderNum(orderNumber);

  },[orderNumber])

  const onSubmit = data => {

 

    const save = async () => {
        try {
          const responseData = await sendRequest(
            `${REACT_APP_HFM_API_URL}/api/lesson/update/${lessonId}`,
            'PATCH',
            JSON.stringify({ lessonNumber:orderNum }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );
          console.log(responseData.lesson);
          if (responseData){
            if (responseData.lesson) {
              updateLesson(responseData.lesson);
            }
           }
          close(orderNum);
        } catch (err) {
          //console.log(err);
          close(orderNum);
        }
      };
      save();
      
  }

  const updateOrderHandler = e => {
      
    if (e.target.value !== '') {
      setOrderNum(parseInt(e.target.value));
    } else {
      setOrderNum(0);
    } 
  }

  return (
    <Fragment>
      {isLoading && <Spinner />}
      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input 
            id='duration'
            name='duration'
            type='number'
            groupFront="order no:"
            value={orderNum} 
            onChange={updateOrderHandler}
            />
          <Button className='price-save-modal-btn' title="save" type='submit' />
        </form>
      )}

      {error && <p>{error}</p>}
    </Fragment>
  );
};

export default UpdateOrderNumber;
