import React from 'react';

import './LoadingDots.css'

const LoadingDots = () => {
    return (
        <div><p className='loading-dots-text'>Loading</p>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default LoadingDots;