import React from 'react'
import PostBody from '../../shared/components/Discussion/PostBody';
import styles from './UserPosts.module.css';
const UserPosts = ({posts}) => {
  return (
    <div className={styles.wrapper}>
<h1> Posts</h1>
    {posts && posts.map(post => (
        <PostBody key={post.id} post={post}/>
    ))}


    </div>
  )
}

export default UserPosts;