import React, { useContext, useEffect, useState } from 'react';

import Checkbox from '../../shared/components/FormElements/Checkbox';
import Button from '../../shared/components/UIElements/Buttons/Button';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingDots from '../../shared/components/UIElements/LoadingDots';

import styles from './ToggleCourse.module.css';

const ToggleCourse = ({ data }) => {

    const { REACT_APP_HFM_API_URL } = process.env;
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [active, setActive] = useState(false);

  useEffect(() => {
    console.log(data);
    data && setActive(data.active);
  }, [data]);

  const onChangeHandler = () => {
    setActive(active ? false : true);
  };

  
  const updateCourseState = async () => {

    try {
      const responseData = await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/course/toggle/${data.id}`,
        'PATCH',
        JSON.stringify({ active: active }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(responseData);
    } catch (err) {
      console.log(err);
    }
  };
  
  

  return (
    <div className={styles.wrapper}>
    <ErrorModal error={error} onClear={clearError} />
      <Checkbox
        id={data.id}
        name={data.id}
        checked={data.active}
        onChange={onChangeHandler}
        isChecked={active}
      />
     {!isLoading && <Button className='button-secondary' title='update' type='button' onClick={updateCourseState}/>}
     {isLoading && <LoadingDots />}
    </div>
  );
};

export default ToggleCourse;
