import React, {useContext, useState} from 'react';
import PdfUpload from '../../../shared/components/FormElements/PdfUpload';

import Button from '../../../shared/components/UIElements/Buttons/Button';
import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../../shared/components/UIElements/Modal/ErrorModal';
import { AuthContext } from '../../../shared/context/auth-context';
import { useHttpClient } from '../../../shared/hooks/http-hook';



const LessonPDFUpload = ({ lessonId, setFile }) => {

  const { REACT_APP_HFM_API_URL } = process.env;
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [chosenFile, setChosenFile] = useState()

  const onSubmit = async event => {
    event.preventDefault();
    try {
      console.log(chosenFile);
      const formData = new FormData();
      formData.append('pdf', chosenFile);

      const response = await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/lesson/upload-pdf/${lessonId}`,
        'PATCH',
        formData,
        {
          Authorization: 'Bearer ' + auth.token,
        }
      );

      setFile(response.result);

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section id="content-image-upload__wrapper">
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <form onSubmit={onSubmit} className="content-image-upload-form">
          <label>
          
          </label>
          <PdfUpload id="file" chosenFile={setChosenFile} />
          <Button
            type="submit"
            className="button-secondary upload-gpimg-btn"
            title="upload"
          />
          <span className="upload-rules">
            files must be .pdf
          </span>
        </form>
      )}
    </section>
  );
};

export default LessonPDFUpload;
