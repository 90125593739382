import React, { Suspense, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faSignOutAlt,
  faEnvelope,
  faExclamationTriangle,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';

import Dashboard from './dashboard/pages/dashboard';
import Courses from './courses/pages/Courses';
import Users  from './users/pages/Users';
import Settings from './settings/pages/Settings';
import SideBar from './shared/components/nav/sidebar';
import Auth from './auth/pages/Auth';
import Forgot from './auth/pages/ForgotPassword';
import Reset from './auth/pages/ResetPassword';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
import Header from './shared/components/header/Header';

import './App.css';

library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faSignOutAlt,
  faCalendarAlt,
  faSearch,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faExclamationTriangle,
  faFilePdf
);

function App() {

  const [pageTitle, setPageTitle] = useState('');
  const setTitle = title => {
    setPageTitle(title);
  }

  const {
    userId,
    firstName,
    lastName,
    userProfileImageUrl,
    email,
    token,
    login,
    logout,
  } = useAuth();

  let routes;

  if (!token) {
    routes = (
      <React.Fragment>
        <Routes>
          <Route path="/auth" exact element={<Auth />}/>
          <Route path="/forgot" exact  element={<Forgot />}/>
          <Route path="/reset/:token" element={ <Reset />}/>
          <Route path="*" element={<Auth />}/>
        </Routes>
      </React.Fragment>
    );
  } else {
    routes = (
      <React.Fragment>
        <Header title={pageTitle} />
        <SideBar />
        <Routes>
          <Route path="/" exact element={<Dashboard setTitle={setTitle}/>}/>
          <Route path="/users" exact element={<Users setTitle={setTitle}/>}/>
          <Route path="/courses" exact element={<Courses setTitle={setTitle}/>}/>
          <Route path="/settings" exact element={<Settings setTitle={setTitle}/>}/>
          <Route path="*" element={<Dashboard setTitle={setTitle}/>}/>
        </Routes>
      </React.Fragment>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userProfileImageUrl: userProfileImageUrl,
        firstName:firstName,
        lastName: lastName,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        <main>
          <Suspense
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
