import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './VideoPlayer.css';

const ResponsivePlayer = props => {
  const { REACT_APP_HFM_API_URL } = process.env;
  const [videoUrl, setVideoUrl] = useState();

  useEffect(() => {
    props.url && props.local && setVideoUrl(props.url);
    props.url &&
      !props.local &&
      setVideoUrl(`${REACT_APP_HFM_API_URL}/${props.url}`);
  }, [REACT_APP_HFM_API_URL, props.local, props.url]);

  return (
    <div className={`player-wrapper ${props.className}`}>
      <ReactPlayer
        className="react-player"
        url={videoUrl}
        width="100%"
        height="100%"
        controls={true}
        onProgress={props.onProgress}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
      />
    </div>
  );
};

export default ResponsivePlayer;
