import React, { Fragment, useContext, useState, useEffect } from 'react';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';
import Button from '../../../shared/components/UIElements/Buttons/Button';
import Spinner from '../../../shared/components/UIElements/LoadingSpinner';

const UpdateAssignmentDesc = ({ lessonId, text, close }) => {
  
  const { REACT_APP_HFM_API_URL } = process.env;
  const { isLoading, error, sendRequest } = useHttpClient();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (text) {
      let contentState = stateFromHTML(text);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [text]);

  const save = async () => {
    const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    //console.log(text);
    try {
      await sendRequest(
        `${REACT_APP_HFM_API_URL}/api/lesson/update/${lessonId}`,
        'PATCH',
        JSON.stringify({ text: text }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );

      close(text);
    } catch (err) {
      //console.log(err);
    }
  };

  const onEditorStateChange = state => {
    setEditorState(state);
  };

  return (
    <Fragment>
      {isLoading && <Spinner />}
      {error && <p className="course-form-editor-error">{error}</p>}
      {text && (
        <Editor
          wrapperClassName="course-form-editor-wrapper"
          editorClassName="editor"
          toolbarClassName="toolbar"
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      )}
      {!isLoading && <Button title="save" onClick={save} />}
    </Fragment>
  );
};

export default UpdateAssignmentDesc;
